h1,
h2,
h3,
h4,
h5,
h6,
b,
strong,
.heading--section,
.card__title,
.heading--large {
  font-weight: 800 !important;
}

.button:not(#mc_embed_signup .button ) {
  color: $nearly-black !important;
}
