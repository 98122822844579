/*Example here is for Open Sans light, regular, semi bold, bold & Raleway medium*/

/*
@import url("https://db.onlinewebfonts.com/c/0d49fc455f4a8951a42daf952412a713?family=Helvetica+Neue+Regular");
@import url("https://db.onlinewebfonts.com/c/6c6db39a56fe8e8f19ba47c6a0770a18?family=Helvetica+Neue+65+Medium");
@import url("https://db.onlinewebfonts.com/c/0c96daa6c25910a00efd1f0ea3c5c02d?family=Helvetica+Neue+W02+75+Bold");
*/

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

/* todo: most places updated to use svg icons. keep these for the short term for legacy support.
  It will cause minimal additional data as fonts shouldn't be downloaded unless used in legacy places */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons&display=swap");
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
@import url(""); // last font import seems to break so adding an empty one
