/* ==========================================================================
   Colours
========================================================================== */

$black: #1D1D1B;
$nearly-black: #10182D;
$link-color: #0B71B9;
$hover-color: #df8106;
$grey-lightest: #ecf2fd;

/*Font sizes*/
$menu-font-size: 1.2rem;
$secondary-menu-font-size: 0.9375rem;
$footer-font-size: 0.875rem;
$footer-font-size-mobile: 0.813rem;

/* ==========================================================================
   Measurements
========================================================================== */


$nav-height: 48px;


/*Section spacing*/
$xsmall-spacing: 20px;
$small-spacing: 30px;
$medium-spacing: 40px;
$large-spacing: 80px;
$medium-button-height: 48px;

$section-margin-desktop:50px;

$card-gap: 30px;

$content-group-top-margin: 15rem;

$thick-border:4px;

$sort-by-width: 25%;
$search-input-width: 400px;
$border-radius-small: 5px;
$border-radius-button: 30px;

$header-height-moblie: 60px;
$header-height-tablet: 80px;
$header-height-desktop: 75px;

$body-font-size: 1.125rem;
$button-font-size: 1.125rem;
$button-font-size-small:.875rem;
$button-height-small: 36px;
$menu-item-icon-colour: #10182D;
$secondary-menu-font-size: .938rem;
$button-padding: 10px 25px 11px 25px;
$success: #6b9e22;
$grid4-card-image-height: 235px;
$logo-height-desktop: 45px;
$logo-height-tablet: 40px;
$logo-height-mobile: 35px;
$h1-font-size: 3rem;
$h2-font-size: 2.25rem;
$row-boxed-width:1210px;
